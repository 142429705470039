<template>
  <div class="home">欢迎使用幼儿教育管理平台！</div>
</template>
<script>
export default {
  components: {},
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
